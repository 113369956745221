/*-------------------------------------------------------------------
  ===== Table of Contents =====

  * Bootstrap functions
  * Template variables
  * SCSS Compass Functions
  * Boostrap Main SCSS
  * Template mixins
    + Animation Mixins
    + Background Mixins
    + Badge Mixins
    + Button Mixins
    + Miscellaneous Mixins
    + BlockQuote Mixins
    + Cards Mixins
    + Color Functions Mixins
  * Core Styles
    + Reset Styles
    + Fonts
    + Functions
    + Backgrounds
    + Typography
    + Miscellaneous
    + Footer
    + Layouts
    + Utilities
    + Demo styles
  * Components
    + Accordions
    + Buttons
  * Landing screens
    + Auth
    + Lock Screen
-------------------------------------------------------------------*/

/*-------------------------------------------------------------------*/
/* === Import Bootstrap functions and variables === */
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

/*-------------------------------------------------------------------*/
/* === Import template variables === */
@import 'variables';

/*-------------------------------------------------------------------*/
/* === SCSS Compass Functions === */
@import "../../node_modules/compass-mixins/lib/compass";
@import "../../node_modules/compass-mixins/lib/animate";

/*-------------------------------------------------------------------*/
/* === Boostrap Main SCSS === */
@import "../../node_modules/bootstrap/scss/bootstrap";

/*-------------------------------------------------------------------*/
/* === Template mixins === */
@import "mixins/misc";
@import "mixins/animation";
@import "mixins/background";
@import "mixins/badges";
@import "mixins/blockqoute";
@import "mixins/buttons";
@import "mixins/breadcrumbs";
@import "mixins/cards";

/*-------------------------------------------------------------------*/
/* === Core Styles === */
@import "reset";
@import "fonts";
@import "functions";
@import "background";
@import "typography";
@import "misc";
@import "footer";
@import "utilities";
@import "demo";

/*-------------------------------------------------------------------*/
/* === Components === */
@import "components/badges";
@import "components/bootstrap-progress";
@import "components/buttons";
@import "components/breadcrumbs";
@import "components/cards";
@import "components/checkbox-radio";
@import "components/tables";
@import "components/preview";

/*-------------------------------------------------------------------*/


@import "components/plugin-overrides/select2";
@import "components/plugin-overrides/typeahead";

/*-------------------------------------------------------------------*/


@import "components/dropdown";
@import "components/forms";
@import "components/icons";
@import "components/lists";
@import "components/tabs";

/*-------------------------------------------------------------------*/
/* === Landing screens === */
@import "auth";
@import "lock-screen";

@import 'navbar';
@import 'sidebar';
@import 'layouts';
@import 'vertical-wrapper';

/* === Custom css === */
@import "dashboard";