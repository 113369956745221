/* Bootstrap Progress */

.progress {
  @include border-radius(3px);
  height: 8px;
  .progress-bar {
    @include border-radius(3px);
  }
  &.grouped {
    .progress-bar {
      border-radius: 0px;

      &:last-child {
        border-radius: 0px 3px 3px 0px;
      }
    }
  }

  &.progress-sm{
    height: 0.375rem;
  }
  &.progress-md {
    height: 8px;
  }
  &.progress-lg {
    height: 15px;
  }
  &.progress-xl {
    height: 18px;
  }
}