////////// COLOR SYSTEM //////////

$blue: #5E50F9;
$indigo: #6610f2;
$purple: #6a008a;
$pink: #E91E63;
$red: #f96868;
$orange: #f2a654;
$yellow: #f6e84e;
$green: #46c35f;
$teal: #58d8a3;
$cyan: #57c7d4;
$black: #000;
$white: #ffffff;
$white-smoke: #f2f7f8;
$violet: #41478a;
$darkslategray : #2e383e;
$dodger-blue : #3498db;


$colors: (blue: $blue,
indigo: $indigo,
purple: $purple,
pink: $pink,
red: $red,
orange: $orange,
yellow: $yellow,
green: $green,
teal: $teal,
cyan: $cyan,
white: $white,
gray: #434a54,
gray-light: #aab2bd,
gray-lighter: #e8eff4,
gray-lightest: #e6e9ed,
gray-dark: #0f1531,
black: #000000);


$theme-colors: (primary: #0062ff,
secondary: #8e94a9,
success: #83B739,
info: #a461d8,
warning: #ffc542,
danger: #fc5a5a,
light: #aab2bd,
dark: #001737);

$theme-gradient-colors: (primary: linear-gradient(to right, #da8cff, #9a55ff),
secondary: linear-gradient(to right, #e7ebf0, #868e96),
success: linear-gradient(to right, #84d9d2, #07cdae),
info: linear-gradient(to right, #90caf9, #047edf 99%),
warning: linear-gradient(to right, #f6e384, #ffd500),
danger: linear-gradient(to right, #ffbf96, #fe7096),
light: linear-gradient(to bottom, #f4f4f4, #e4e4e9),
dark: linear-gradient(89deg, #5e7188, #3e4b5b));

//$social-colors: (twitter: #4DBFF5, facebook: #648ACA, google: #E55F4B, linkedin: #0177b4, pinterest: #cc2127, youtube: #e52d27, github: #333333, behance: #1769ff, dribbble: #ea4c89, reddit: #ff4500);

////////// COLOR SYSTEM //////////

////////// COLOR VARIABLES //////////
$content-bg: #f0f1f6;
$footer-bg: $content-bg;
$footer-color: theme-color(light);
$border-color: rgba(151,151,151, .3);
////////// COLOR VARIABLES //////////

////////// SOCIAL COLORS //////////

$social-colors: (twitter: #2caae1,
facebook: #3b579d,
google: #dc4a38,
linkedin: #0177b5,
pinterest: #cc2127,
youtube: #e52d27,
github: #333333,
behance: #1769ff,
dribbble: #ea4c89,
reddit: #ff4500);

////////// SOCIAL COLORS //////////

////////// FONTS//

$type1-light: 'nunito-light',
sans-serif;
$type1-regular: 'nunito-regular',
sans-serif;
$type1-medium: 'nunito-medium',
sans-serif;
$type1-bold: 'nunito-bold',
sans-serif;

$default-font-size: .875rem; // 14px as base font size

$text-muted: #a7afb7;
$body-color: #a7afb7;

////////// FONT VARIABLES //////////

///////// CARD ////////
$card-bg: #fff;
$card-description-color: #76838f;
$card-title-color: #001737;
///////// CARD ////////

//////// TABLES ////////
$table-accent-bg: $content-bg;
$table-hover-bg: $content-bg;
$table-cell-padding: .9375rem;
$table-border-color: $border-color;
$table-inverse-bg: #2a2b32;
$table-inverse-color: color(white);
//////// TABLES ////////

////////// MODALS VARIABLES //////////
$modal-content-bg: $content-bg;
////////// MODALS VARIABLES ////////// 

///////// NAVBAR ////////
$navbar-bg: #ffffff;
$navbar-height: 64px;
$navbar-menu-color: #111111;
$navbar-font-size: .875rem;
$navbar-icon-font-size: 1.25rem;
$nvabar-brand-bg: #181824; 
$nvabar-toggler-color: #8e94a9;
$nvabar-search-icon-color: #8e94a9;
$navbar-search-bg: #eef0fa;
$nav-dropdown-color: #111111;

///////// NAVBAR ////////



///////// BUTTONS ////////

$button-fixed-width: 150px;
$btn-padding-y: .775rem;
$btn-padding-x: .75rem;
$btn-line-height: 1;

$btn-padding-y-xs: .5rem;
$btn-padding-x-xs: .75rem;

$btn-padding-y-sm: .50rem;
$btn-padding-x-sm: .81rem;

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 3rem;

$btn-font-size: .875rem;
$btn-font-size-xs: .625rem;
$btn-font-size-sm: .875rem;
$btn-font-size-lg: .875rem;

$btn-border-radius: .1875rem;
$btn-border-radius-xs: .1875rem;
$btn-border-radius-sm: .1875rem;
$btn-border-radius-lg: .1875rem;

///////// BUTTONS ////////



////////// TOOLTIP VARIABLES //////////

//default styles
$tooltip-font-size: .75rem;
$tooltip-padding-y: .4rem;
$tooltip-padding-x: .75rem;
$tooltip-border-radius: .375rem;

////////// TOOLTIP VARIABLES //////////



/////////  FORMS /////////

$input-bg: color(white);
$input-border-radius: 2px;
$input-placeholder-color: #181824;
$input-font-size: .8125rem;

$input-padding-y: 0.94rem;
$input-padding-x: 1.375rem;
$input-line-height: 1;

$input-padding-y-xs: .5rem;
$input-padding-x-xs: .75rem;

$input-padding-y-sm: .50rem;
$input-padding-x-sm: .81rem;

$input-padding-y-lg: .94rem;
$input-padding-x-lg: 1.94rem;

$input-height:          2.875rem;
$input-height-sm:       2.575rem;
$input-height-lg:       3.175rem;

///////// FORMS /////////

////////  DROPDOWNS ///////
$dropdown-border-color: $border-color;
$dropdown-divider-bg: $border-color;
$dropdown-link-color: $body-color;
$dropdown-header-color: $body-color;
$dropdown-menu-box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
////////  DROPDOWNS ///////


////////// MEASUREMENT AND PROPERTY VARIABLES //////////

$boxed-container-width: 1200px;
$border-property: 1px solid $border-color;
$card-spacing-y: 30px;
$card-padding-y: 2.5rem;
$card-padding-x: 2.5rem;
$card-border-radius: .3125rem;
$grid-gutter-width: 18px;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
////////// OTHER VARIABLES //////////



////////// BREAD CRUMBS VARIABLES //////////

// default styles
$breadcrumb-padding-y: 0.56rem;
$breadcrumb-padding-x: 1.13rem;
$breadcrumb-item-padding: .5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-font-size: $default-font-size;
$breadcrumb-bg: transparent;
$breadcrumb-border-color: $border-color;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-700;
$breadcrumb-divider: "/";

// custom styles
$breadcrumb-custom-padding-y: 0;
$breadcrumb-custom-padding-x: 0;
$breadcrumb-custom-item-padding-y: 0.56rem;
$breadcrumb-custom-item-padding-x: 10px;
$breadcrumb-custom-item-color: $black;
$breadcrumb-item-bg: #dbe3e6;
////////// BREAD CRUMBS VARIABLES //////////


////////// MODALS VARIABLES //////////
$modal-inner-padding: 15px;
$modal-dialog-margin: 10px;
$modal-dialog-margin-y-sm-up: 30px;
$modal-title-line-height: $line-height-base;
$modal-content-box-shadow-xs: 0 3px 9px rgba($black, .5);
$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black, .5);

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: .5;

$modal-header-border-color: $border-color;
$modal-content-border-color: $border-color;
$modal-footer-border-color: $border-color;

$modal-header-border-width: $border-width;
$modal-content-border-width: $border-width;
$modal-footer-border-width: $border-width;

$modal-header-padding-x: 26px;
$modal-header-padding-y: 25px;

$modal-body-padding-x: 26px;
$modal-body-padding-y: 35px;

$modal-footer-padding-x: 31px;
$modal-footer-padding-y: 15px;

$modal-lg: 90%;
$modal-md: 500px;
$modal-sm: 300px;
$modal-transition: transform .4s ease;

////////// MODALS VARIABLES //////////

/////////  TABS VARIABLES //////////
$nav-tabs-border-color: #e3e5ef;
$nav-tabs-link-bg: #f6f8fa;
$nav-tabs-link-color: #000000;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-link-active-bg;
$nav-pills-custom-bg: #fcfcfd;
////////   TABS VARIABLES /////////


///////// SETTINGS PANEL ////////
$settings-panel-width: 300px;
///////// SETTINGS PANEL ////////


////////   CALENDAR  //////////////
$fullcalendar-color: #a8b2b9;
////////   CALENDAR  //////////////

////////   MISC  //////////////
$page-title-color: #111;
////////   MISC  //////////////

$card-mixed-danger :linear-gradient(to bottom, #d41459 , #911a6c);

////////   CHAT  //////////////
$chat-container-height:950px;
////////   CHAT  //////////////

$bullet-line-list-shape-bg: color(white) !default;

////////// SIDEBAR ////////
$sidebar-width-lg: 258px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px; 

$sidebar-dark-bg:#181824;
$sidebar-dark-menu-color: #8e94a9;
$sidebar-dark-submenu-color: #888;
$sidebar-dark-menu-active-bg: transparent;
$sidebar-dark-menu-active-color: theme-color(success);
$sidebar-dark-menu-hover-bg: darken($sidebar-dark-bg, 1%);
$sidebar-dark-menu-hover-color: #fff;
$sidebar-dark-submenu-hover-color: darken($sidebar-dark-submenu-color, 20%);
$sidebar-dark-menu-icon-color: #8e94a9;
$sidebar-dark-menu-arrow-color: rgba(255,255,255,.36);
$sidebar-dark-menu-border-color: #f2edf3;
$sidebar-dark-icon-bg: rgba(194,244,219,.12);
$sidebar-dark-icon-color: #33c92d;
$sidebar-dark-action-border: rgba(151,151,151,.27); 


$sidebar-light-bg: #fff;
$sidebar-light-menu-color: #444343;
$sidebar-light-menu-active-bg: #fff;
$sidebar-light-menu-active-color: #444343;
$sidebar-light-menu-hover-bg: lighten($sidebar-light-bg, 10%);
$sidebar-light-menu-hover-color: #27367f;
$sidebar-light-submenu-color: $sidebar-light-menu-color;
$sidebar-light-submenu-hover-bg: initial;
$sidebar-light-submenu-hover-color: darken($sidebar-light-submenu-color, 30%);
$sidebar-light-icon-active-color: theme-color(success);
$sidebar-light-icon-bg: rgba(0,0,0,.12);

$sidebar-light-menu-icon-color: $sidebar-light-icon-active-color; 
$sidebar-light-menu-arrow-color: #9e9da0;

$sidebar-menu-font-size: 0.937rem;
$sidebar-menu-padding-y: .625rem;
$sidebar-menu-padding-x: 2.375rem;

$sidebar-submenu-font-size: .8125rem;
$sidebar-submenu-item-padding: .75rem 2rem .75rem 2rem;

$sidebar-icon-font-size: 1.125rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: .75rem 1.625rem .75rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$rtl-sidebar-submenu-padding: 0 1.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-dark: $sidebar-dark-menu-hover-bg;
$icon-only-menu-bg-light: $sidebar-light-menu-hover-bg;

///////// SIDEBAR ////////