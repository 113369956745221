/* Dropdowns */

.dropdown {
  .dropdown-toggle {
    &:after {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      font: normal normal normal 24px/1 "Material Design Icons";
      content: "\f35d";  
      width: auto;
      height: auto;
      vertical-align: baseline; 
      font-size: .75rem;
      .rtl & {
        margin-left: 0;
        margin-right: 0.255em;
      }
    }
  }
  .dropdown-menu {
    margin-top: .75rem;
    font-size: $default-font-size;
    box-shadow: $dropdown-menu-box-shadow; 
    border-radius: 0;
    .dropdown-item {
      font-size: 1rem;
      padding: .25rem 1.5rem;
      &:active {
        background: initial;
      }
    }
  }
  &.dropdown-arrow-none {
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }
  }
  .rtl & {
    .dropdown-header {
      text-align: right;
    }
    .dropdown-item {
      text-align: right;
    }
  }
}
